@use '../Abstracts/mixins' as m;
@use '../Abstracts/breakpoints' as *;
@use '../Abstracts/vars' as *;

.home--container {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    align-items: center;
    justify-items: center;
    padding: 1.5rem 13.5vw 0 /*1.5rem 10rem */;
    @include m.simpleOpacityAnimation();
    min-height: var(--min-height);
    section {
      z-index: 888;
    }
    .home--heroText {
      max-width: 37vw; /* 26rem */
      @include m.simpleOpacityAnimation();
        &::before {
            content: "Hi!";
            position: absolute;
            background: (var(--gradient), 80%);
            color: var(--blob-color);
            @include m.rainbowAnimation();
            background-size: 200% auto;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: 'Seaweed Script', cursive;
            font-size: calc(3.8rem * var(--text-multiplier2));
            transform: rotate(-36deg) translate(0%, -80%);
            @include media ("<=tablet") {
                transform: rotate(-36deg) translate(30%, -75%);
            }
        }
        h1 {
            font-weight: 700;
            font-size: calc(1.8rem * var(--text-multiplier)) ;
            text {
              font-size: calc(1.3rem * var(--text-multiplier)) ;
              font-weight: 500;
              opacity: 0.75;
              font-family: 'Courier Prime', monospace;
              @include media ("<=tablet") {
                font-size: calc(1rem * var(--text-multiplier)) ;
              }
            }
        }
        p {
            font-size: calc(1.2rem * var(--text-multiplier3));
            font-family: 'Courier Prime', monospace;
            margin: calc(1.8rem * var(--text-multiplier1)) 0 calc(1.5rem * var(--text-multiplier2));
            .hero--spanCompEngg {
              @include m.spanHighlight();
            }
            .dynamic--words {
              color: var(--blob-color);
              font-size: calc(1.2rem * var(--text-multiplier3));
              font-weight: 600;
              font-family: 'Courier Prime', monospace;
              display: none;
              &.text-in{
                display: inline-block;
                @include m.easeInSlideDownText();
              }
              &.text-out{
                @include m.easeOutSlideDownText();
              }
            }
        }
        a {
          text-align: center;
          text-decoration: none;
          width: fit-content;
          .home--heroButton {
            @include m.slideInFromLeft();
            @include m.mainButton();
            border-radius: 0.35rem;
            border: none;
            transition: all 0.3s ease-in-out;
            .center--heroLinkedin {
              @include m.simpleFlexy();
              gap: 0.6rem;
              .linkedin--icon {
                font-size: calc(1.8rem * var(--text-multiplier3));
              }
            }
            &:active {
              @include m.onButtonActive(0.7rem, var(--color-bg));
            }
            &:hover {
              background-color: var(--blob-color);
              color: var(--color-bg);
            }
            @include media ("<=tablet") {
              font-size: calc(1rem * var(--text-multiplier)) ;
              padding: 0.4rem 0.8rem;
            }
          }
        }
        @include media ("<=tablet") {
          max-width: 82vw;
        }
    }
    .home--heroImage {
      .hero--img {
        @include m.slideInFrom (9%, 0%);
        width: 24vw;
        @include media ("<=tablet") {
          width: 45vw;
        }
      }
    }
    .blob-motion {
      @include m.simpleOpacityAnimation();
      position: absolute;
      transform: translate(0%, 0%);
      width: 75%;
      z-index: 0;
      @include media ("<=tablet") {
        width: 100%;
        transform: translate(-25%, 23%);
      }
      @include media ("<=375px") {
        transform: translate(-25%, 15%);
      }
      @include media (">=1600px") {
        transform: translate(5%, 0%);
      }
    }
    @include media ("<=tablet") {
      display: grid;
      grid-template: auto auto / 82vw;
      justify-content: center;
      row-gap: 1.5rem;
      padding: 4rem 9vw 0;  /* 3.5rem */
    }
  }


