@use '../Abstracts/mixins' as m;
@use '../Abstracts/breakpoints' as *;
@use '../Abstracts/vars' as *;

.skills--container {
    @include m.simpleOpacityAnimation();
    @include m.simpleFlexy();
    flex-direction: column;
    text-align: center;
    min-height: var(--min-height);
    gap: calc(3.8rem * var(--text-multiplier3));
    padding: 2.5rem 0 1.5rem;
    @include media ("<=tablet") {
        @include m.griddy();
        row-gap: 3.3rem;
        padding: 2.5rem 2vw 1rem;
    }
    .skills--technicalClass, 
    .skills--creativeClass {
        z-index: 950;
        background-color: var(--color-bg);
        border: solid calc(0.3rem * var(--text-multiplier2)) var(--color-pp);
        max-width: calc(60vw * var(--widthGrower));
        padding: 0 calc(3rem * var(--text-multiplier1)) calc(2rem * var(--text-multiplier1));
        .centered--skills-sectionTitle {
            @include m.simpleFlexy();
            .skills--sectionTitle, .skills--sectionIcon {
                @include m.sectionTitle();
            }
            .skills--sectionIcon {
                margin: 0 !important;
                filter: drop-shadow(0.08rem 0.05rem black);
            }
        }
        p {
            margin-bottom: calc(1.5rem * var(--text-multiplier2));
            line-height: 1.6rem;
            font-size: 1rem;
            @include media (">1440px") {
                font-size: calc(1rem * var(--text-multiplier3));
            }
            text-align: left;
            span {
                @include m.spanHighlight();
            }
        }
        .skill--reactIcon {
            color: var(--color-pp);
            font-size: calc(5rem * var(--text-multiplier1));
            margin: 1rem;
            &:hover {
                @include m.iconHover();
            }
        }
        @include media ("<=tablet") {
            padding: 0 1.3rem 1.1rem;
        }
    }
    .skills--technicalClass {
        box-shadow: 0.6rem 0.6rem 0 0 var(--blob-color);
        @include m.bounceToRight(-6.25rem, 1.25rem);
    }
    .skills--creativeClass {
        box-shadow: -0.6rem 0.6rem 0 0 var(--blob-color);
        @include m.bounceToLeft(6.25rem, -1.25rem); 
    }
    #skills--pdfContainer {
        max-width: calc(60vw * var(--widthGrower));
        @include m.simpleOpacityAnimation();
        .skills--licensesPdfBtn {
            @include m.mainButton();
            font-weight: 600;
            text-align: center;
            transition: all 0.3s ease-in-out;
            margin: calc(0.3rem * var(--text-multiplier2)) 0 calc(1.4rem * var(--text-multiplier1));
            font-size: calc(1rem * var(--text-multiplier));
            div {
                @include m.simpleFlexy();
                gap: 0.7rem;
            }
            &:hover {
                background-color: var(--blob-color);
            }
            &:active {
                @include m.onButtonActive(0.4rem, var(--color-bg));
            }
        }
        #pdfIframe {
            @include m.bounceToRight(-6.25rem, 1.25rem);
            margin-bottom: calc(1.8rem * var(--text-multiplier1));
        }
    }
    .redundantSVG3 {
        @include m.bounceToLeft(6.25rem, -1.25rem);
        @include media (">=1680px") {
            right: 12rem;
        }
    } 
    .redundantSVG4 {
        @include m.bounceToRight(-6.25rem, 1.25rem);
        @include media (">=1680px") {
            left: 12rem;
        }
      }
}