@use '../Abstracts/mixins' as m;
@use '../Abstracts/breakpoints' as *;
@use '../Abstracts/vars' as *;

.footer--container {
    @include m.simpleOpacityAnimation();
    position: relative;
    text-align: center;
    padding: 1.2rem 1rem 0.3rem;
    z-index: 997;
    .footer--iconsContainer {
       @include m.simpleFlexy(); 
        gap: 2.4rem;
        margin-bottom: 0.3rem;
        transition: all 0.5s ease-in-out;
        a {
            color: var(--color-pp);
            &:hover {
                @include m.iconHover();
            }
        }
    }
    .footer--spanTextContainer {
        margin: 0.6rem 0 0.5rem;
        span {
            opacity: 0.85;
            color: var(--color-pp);
            font-size: calc(0.8rem * var(--text-multiplier)) ;
            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
            p {
                font-size: calc(0.75rem * var(--text-multiplier)) ;
                margin-top: 0.1rem;
            }
        }
    }
}



