@use '../Abstracts/mixins' as m;
@use '../Abstracts/breakpoints' as *;
@use '../Abstracts/vars' as *;

.projects--container {
    text-align: center;
    padding: 1.7rem 1rem 1.4rem;
    min-height: var(--min-height);
    @include m.simpleOpacityAnimation();
    .projects--mainButton {
        @include m.mainButton();
        border: none;
        &:hover {
          opacity: 0.9;
        }
        @include media ("<=tablet") {
          margin-top: 0.8rem;
        }
    }
    .projects--mainButton-active {
        @include m.onButtonActive(0.3rem, var(--main-blackey));
        cursor: default;
        background-color: var(--blob-color);
        color: var(--white);
        transform: scale(0.85);
        transition: transform 0.3s ease;
        &:hover {
          opacity: 1;
        }
    }
}

.projects--technicalContainer, 
.projects--creativeContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include m.simpleOpacityAnimation();
  gap: calc(5rem * var(--text-multiplier1));
  margin: calc(3.5rem * var(--text-multiplier)) 0 calc(1rem * var(--text-multiplier));
  .singleProject--technicalContainer, 
  .singleProject--creativeContainer {
      @include m.griddy();
      @include m.simpleOpacityAnimation();
      z-index: 950;
      text-align: center;
      .projectImage {
          width: calc(25rem * var(--text-multiplier3));
          transition: all 0.7s ease;
          transition: transform 0.3s ease;
          &:hover {
              filter: brightness(90%);
              transform: scale(1.03);
          }
      }
      .projectVideo {
        width: 21rem;
        margin-bottom: calc(0.65rem * var(--text-multiplier));
      }
      h2 {
        font-size: calc(1.5rem * var(--text-multiplier3));
        margin-bottom: calc(0.25rem * var(--text-multiplier));
      }
      p {
        font-weight: 500;
        margin-bottom: calc(0.25rem * var(--text-multiplier));
      }
      a {
        text-decoration: none;
        width: fit-content;
        justify-self: center;
        .singleProject--btn {
          background-color: var(--color-bg);
          padding: calc(0.4rem * var(--text-multiplier2)) calc(0.8rem * var(--text-multiplier2));
          margin: calc(0.25rem * var(--text-multiplier2)) 0;
          color: var(--color-pp);
          font-size: calc(1.1rem * var(--text-multiplier));
          font-weight: 600;
          cursor: pointer;
          border-radius: 1rem;
          border: solid 0.125rem var(--color-pp);
          transition: all 0.3s ease-in-out;
          .centerDiv--singleProjectIcon {
            @include m.simpleFlexy();
            gap: 0.5rem;
            .singleProject--icon {
              font-size: calc(1.4rem * var(--text-multiplier2));
            }
          }
          &:hover {
            color: var(--blob-color);
            border-color: var(--blob-color);
          }
          &:active {
            @include m.onButtonActive(0.2rem, var(--blob-color));
            color: var(--color-bg);
            background-color: var(--blob-color);
          }
        }
      }
  }
  .singleProject--creativeContainer {
    @include m.skillsAndProjectsBoxShadow(0, var(--color-pp));
    background-color: var(--color-bg);
    border-radius: 0 0 0.5rem 0.5rem;
    transition: all 0.3s ease;
    &:hover {
      @include m.skillsAndProjectsBoxShadow(0, var(--blob-color));
      transform: scale(1.03);
    }
    h2, p {
      cursor: default;
    }
    &:hover h2 {
      color: var(--blob-color);
    }
  }
  .redundantSVG1, .redundantSVG2, .redundantSVG3, .redundantSVG4 {
    @include m.opacityAndBlur();
    width: calc(19rem * var(--text-multiplier1));
  }
}
.projects--technicalContainer {
  .redundantSVG2 {
    top: 30rem;
    @include media (">1440px") {
      top: 25rem;
    }
  }
}
.projects--creativeContainer {
  .redundantSVG1, .redundantSVG2, .redundantSVG3, .redundantSVG4 {
    width: calc(33rem * var(--text-multiplier1));
	  opacity: 0.2;
    transform: scaleX(-1);
    @include media ("<=tablet") {
      width: 11rem !important;
    }
  }
  .redundantSVG1 {
    top: 75rem;
    @include media (">1440px") {
      top: 55rem;
    }
  }
}