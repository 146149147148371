@use './vars' as v;

// DISPLAY PROPERTIES
@mixin navmaingriddy {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}

@mixin griddy {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
}

@mixin flexy {
    display: flex;
    justify-content: space-between;
}

@mixin simpleFlexy {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin everyLink {
    font-size: 1.8rem;
    text-decoration: none;
}

@mixin sectionTitle {
  font-size: calc(2.4rem * var(--text-multiplier2));
  text-align: center;
  color: var(--blob-color);
  margin: calc(2rem * var(--text-multiplier2)) calc(1rem * var(--text-multiplier2));
  text-shadow: 0.08rem 0.05rem black;
}

@mixin mainButton {
  background-color: var(--color-pp);
  padding: calc(0.7rem * var(--text-multiplier)) calc(1.2rem * var(--text-multiplier));
  color: var(--white);
  font-size: calc(1.1rem * var(--text-multiplier)) ;
  font-weight: 600;
  cursor: pointer;
}

@mixin spanHighlight {
  text-decoration: underline dotted;
  text-underline-position: under;
  text-decoration-color: var(--blob-color);
  &:hover {
      color: var(--color-bg);
      background-color: var(--color-pp);
      text-decoration: none;
  }
}

@mixin forPopUp {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  border: solid calc(0.35rem * var(--text-multiplier2)) var(--color-pp);
  box-shadow: 0.7rem 0.7rem 0 0 var(--blob-color);
  background-color: var(--color-bg);
} 

@mixin iconHover {
  color: var(--blob-color);
  transform: translateY(-10%) scale(1.1);
  transition: transform 0.1s ease-out;
}

// BOX SHADOW
@mixin onButtonActive($argument1, $argument2) {
  -webkit-box-shadow: inset 0rem 0rem $argument1 $argument2;
  -moz-box-shadow: inset 0rem 0rem $argument1 $argument2;
  box-shadow: inset 0rem 0rem $argument1 $argument2;
  outline: none;
  transition: none;
}

@mixin skillsAndProjectsBoxShadow($argument1, $argument2) {
  box-shadow: 0 $argument1 calc(1rem * var(--text-multiplier2)) calc(-0.4rem * var(--text-multiplier2)) $argument2;
  -webkit-box-shadow: 0 $argument1 calc(1rem * var(--text-multiplier2)) calc(-0.4rem * var(--text-multiplier2)) $argument2;
  -moz-box-shadow: 0 $argument1 calc(1rem * var(--text-multiplier2)) calc(-0.4rem * var(--text-multiplier2)) $argument2;
}

// OPACITY & BLUR
@mixin opacityAndBlur {
  opacity: 0.3;
  filter: blur(0.6rem);
  -webkit-filter: blur(0.6rem);
  transform: translate3d(0, 0, 0);
}

// ANIMATIONS
@mixin animationEaseOut($argument) {
  animation: 0.5s ease-out 0s 1 $argument;
  -webkit-animation: 0.5s ease-out 0s 1 $argument;
  -moz-animation: 0.5s ease-out 0s 1 $argument;
  -o-animation: 0.5s ease-out 0s 1 $argument;
}
@mixin infiniteAnimationEase($argument) {
  animation: $argument 4s ease infinite;
  -webkit-animation: $argument 4s ease infinite;
  -moz-animation: $argument 4s ease infinite;
  -o-animation: $argument 4s ease infinite;
}

@mixin rainbowAnimation {
    @include infiniteAnimationEase(rainbow);
    @-webkit-keyframes rainbow {
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }
    @-moz-keyframes rainbow {
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }
    @-o-keyframes rainbow {
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }
    @keyframes rainbow { 
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }
}

@mixin easeInSlideDownText {
  animation: textIn .5s ease;
  @keyframes textIn{
    0%{
      transform: translateY(125%);
    }
    100%{
      transform: translateY(0%);
    }
  }
}

@mixin easeOutSlideDownText {
  animation: textOut .5s ease;
  @keyframes textOut{
    0%{
      transform: translateY(0%);
    }
    100%{
      transform: translateY(-125%);
  
    }
  }
}

@mixin slideInFrom ($argument1, $argument2) {
  @include animationEaseOut(slideInFrom);
  @keyframes slideInFrom {
      0% {
        transform: translate($argument1,$argument2);
      }
      100% {
        transform: translate(0, 0);
      }
    }
  @-o-keyframes slideInFrom {
      0% {
        transform: translate($argument1,$argument2);
      }
      100% {
        transform: translate(0, 0);
      }
    }
  @-moz-keyframes slideInFrom {
      0% {
        transform: translate($argument1,$argument2);
      }
      100% {
        transform: translate(0, 0);
      }
    }
  @-webkit-keyframes slideInFrom {
      0% {
        transform: translate($argument1,$argument2);
      }
      100% {
        transform: translate(0, 0);
      }
    }
}

@mixin slideInFromLeft {
  @include animationEaseOut(slideInFromLeft);
  @keyframes slideInFromLeft {
      0% {
        transform: translateX(-9%);
      }
      100% {
        transform: translateX(0);
      }
    }
  @-o-keyframes slideInFromLeft {
      0% {
        transform: translateX(-9%);
      }
      100% {
        transform: translateX(0);
      }
    }
  @-moz-keyframes slideInFromLeft {
      0% {
        transform: translateX(-9%);
      }
      100% {
        transform: translateX(0);
      }
    }
  @-webkit-keyframes slideInFromLeft {
      0% {
        transform: translateX(-9%);
      }
      100% {
        transform: translateX(0);
      }
    }
}

@mixin bounceToRight($argument1, $argument2) {
  animation: bounceToRight 0.6s;
  -webkit-animation: bounceToRight 0.6s;
  -moz-animation: bounceToRight 0.6s;
  -o-animation: bounceToRight 0.6s;
  @keyframes bounceToRight {
      0% {
        opacity: 0;
        -webkit-transform: translateX($argument1);
      }
    
      60% {
        opacity: 1;
        -webkit-transform: translateX($argument2);
      }
    
      100% {
        -webkit-transform: translateX(0);
      }
  }
  @-webkit-keyframes bounceToRight {
      0% {
        opacity: 0;
        -webkit-transform: translateX($argument1);
      }
    
      60% {
        opacity: 1;
        -webkit-transform: translateX($argument2);
      }
    
      100% {
        -webkit-transform: translateX(0);
      }
  }
  @-moz-keyframes bounceToRight {
      0% {
        opacity: 0;
        -webkit-transform: translateX($argument1);
      }
    
      60% {
        opacity: 1;
        -webkit-transform: translateX($argument2);
      }
    
      100% {
        -webkit-transform: translateX(0);
      }
  }
  @-o-keyframes bounceToRight {
      0% {
        opacity: 0;
        -webkit-transform: translateX($argument1);
      }
    
      60% {
        opacity: 1;
        -webkit-transform: translateX($argument2);
      }
    
      100% {
        -webkit-transform: translateX(0);
      }
  }
}
@mixin bounceToLeft($argument1, $argument2) {
  animation: bounceToLeft 0.6s;
  -webkit-animation: bounceToLeft 0.6s;
  -moz-animation: bounceToLeft 0.6s;
  -o-animation: bounceToLeft 0.6s;
  @keyframes bounceToLeft {
      0% {
        opacity: 0;
        -webkit-transform: translateX($argument1);
      }
    
      60% {
        opacity: 1;
        -webkit-transform: translateX($argument2);
      }
    
      100% {
        -webkit-transform: translateX(0);
      }
  }
  @-webkit-keyframes bounceToLeft {
      0% {
        opacity: 0;
        -webkit-transform: translateX($argument1);
      }
    
      60% {
        opacity: 1;
        -webkit-transform: translateX($argument2);
      }
    
      100% {
        -webkit-transform: translateX(0);
      }
  }
  @-moz-keyframes bounceToLeft {
      0% {
        opacity: 0;
        -webkit-transform: translateX($argument1);
      }
    
      60% {
        opacity: 1;
        -webkit-transform: translateX($argument2);
      }
    
      100% {
        -webkit-transform: translateX(0);
      }
  }
  @-o-keyframes bounceToLeft {
      0% {
        opacity: 0;
        -webkit-transform: translateX($argument1);
      }
    
      60% {
        opacity: 1;
        -webkit-transform: translateX($argument2);
      }
    
      100% {
        -webkit-transform: translateX(0);
      }
  }
}

@mixin simpleSlideUpDown ($argument) {
  @include animationEaseOut(simpleSlideUpDown);
    @keyframes simpleSlideUpDown {
      0% {
        transform: translateY($argument);
        opacity: 0.2;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    @-o-keyframes simpleSlideUpDown {
      0% {
        transform: translateY($argument);
      }
      100% {
        transform: translateY(0);
      }
    }
    @-moz-keyframes simpleSlideUpDown {
      0% {
        transform: translateY($argument);
      }
      100% {
        transform: translateY(0);
      }
    }
    @-webkit-keyframes simpleSlideUpDown {
      0% {
        transform: translateY($argument);
      }
      100% {
        transform: translateY(0);
      }
    }
}

@mixin simpleOpacityAnimation {
  animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  @keyframes simpleOpacityAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

