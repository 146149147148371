@use '../Abstracts/vars' as *;
@use '../Abstracts/mixins' as m;
@use '../Abstracts/breakpoints' as *;

.about--container {
    @include m.simpleOpacityAnimation();
    @include m.simpleFlexy();
    flex-direction: column;
    padding: 0 calc(10vw * var(--text-multiplier2)) 1.4rem;
    margin-bottom: calc(2rem * var(--text-multiplier1));
    min-height: var(--min-height);
    .centered--about-sectionTitle {
        @include m.simpleFlexy();
        .about--sectionTitle, .about--sectionIcon {
            @include m.sectionTitle();
            @include m.simpleOpacityAnimation();
        }
        .about--sectionIcon {
            margin: 0 !important;
            filter: drop-shadow(0.08rem 0.05rem black);
        }
    }
    .verticalTimelineClass {
        z-index: 950;
        margin-bottom: calc(0.8rem * var(--text-multiplier1));
    }
    .redundantSVG1, .redundantSVG2, .redundantSVG3 {
        width: calc(14rem * var(--text-multiplier2));
    }
    .redundantSVG1 {
        top: calc(90.5rem * var(--text-multiplier3));
        @include m.bounceToRight(-6.25rem, 1.25rem);
        @include media (">1440px") {
            top: 82rem;
        }
    }
    .redundantSVG2, .redundantSVG3 {
        @include m.bounceToLeft(6.25rem, -1.25rem);
    }
    .redundantSVG3 {
        top: 64rem;
        right: 10rem;
        transform: scaleX(-1);
    }
}
