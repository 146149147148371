@use '../Abstracts/mixins' as m;
@use '../Abstracts/breakpoints' as *;
@use '../Abstracts/vars' as *;

.error--container {
    @include m.simpleOpacityAnimation();
    @include m.simpleFlexy();
    padding: 2rem 1rem;
    flex-direction: column;
    min-height: var(--min-height);
    h1 {
        font-size: calc(4.5rem * var(--text-multiplier2));
    }
    p {
        font-size: calc(1.5rem * var(--text-multiplier2));
    }
    .error--reactIcon {
        font-size: calc(14rem * var(--text-multiplier2));
        color: var(--color-pp);
        opacity: 0.6;
    }
    .error--backLink {
        @include m.simpleOpacityAnimation();
        color: var(--color-pp);
        font-size: calc(1.8rem * var(--text-multiplier2));
        &:hover {
            color: var(--blob-color);
        }
    }
}


