@use './Abstracts/vars' as  *;
@use './Abstracts/mixins' as  *;
@use './Abstracts/breakpoints' as  *;
@use './Modules/navbar.scss';
@use './Modules/footer.scss';
@use './Modules/error.scss';
@use './Modules/home.scss';
@use './Modules/about.scss';
@use './Modules/skills.scss';
@use './Modules/projects.scss';
@use './Modules/contact.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }

  *img {
    -webkit-user-drag: none;
  }

  main {
    background-color: var(--color-bg);
    color: var(--color-pp);
    transition: all 500ms ease-in-out;
    min-height: 100vh;
    .scrollUp--button {
      position: fixed;
      bottom: 10% /* 40px */;
      right: 6% /* 25px */;
      z-index: 9999;
      color: var(--color-bg);
      background-color: var(--blob-color);
      font-size: 3rem;
      cursor: pointer;
      @include simpleOpacityAnimation();
      transition: all .3s ease-in-out;
      &:hover {
        @include iconHover();
        color: var(--blob-color);
        background-color: var(--color-bg);
        border: 0.2rem solid var(--blob-color);
      }
    }
  }


// INPUT FIELD
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

// SCROLLBAR
  ::-webkit-scrollbar {
    width: 0.85rem;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--black);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: hsl(0, 0%, 66%);
    border-radius: 0.35rem;
    /* Handle on hover */
    &:hover {
      background: hsl(0, 0%, 80%);
      @include onButtonActive(0.25rem, hsl(0, 0%, 69%));
    }
    /* Handle on active */
    &:active {
      background: var(--white);
      @include onButtonActive(0.4rem, hsl(0, 0%, 30%));
    }
  }

// REDUNDANT BACKGROUND SVGs
  .redundantSVG1, .redundantSVG2, .redundantSVG3, .redundantSVG4 {
    width: 14rem;
    overflow: hidden !important;
    @include simpleOpacityAnimation();
    transition: all 500ms ease-in-out;
    position: absolute;
    @include media ("<=950px") {
      @include opacityAndBlur();
    }
  }
  .redundantSVG1 {
    top: 90rem;
    left: 10rem;
  }
  .redundantSVG2 {
    top: 15rem;
    right: 10rem;
  }
  .redundantSVG3 {
    top: 56rem;
    right: 7rem;
  }
  .redundantSVG4 {
    top: 15rem;
    left: 9rem;
  }