@use "../Abstracts/mixins" as m;
@use "../Abstracts/breakpoints" as *;
@use "../Abstracts/vars" as *;

.contact--container-lowOpacity {
  @include m.opacityAndBlur();
}
.contact--container, .contact--container-lowOpacity {
  min-height: var(--min-height);
  @include m.simpleOpacityAnimation();
  @include m.simpleFlexy();
  flex-wrap: wrap;
  gap: calc(1.5rem * var(--text-multiplier3));
  .contact--imgContainer {
    @include m.simpleFlexy();
    @include m.bounceToRight(-6.25rem, 1.25rem);
    z-index: 950;
    .contact--png {
      width: 30vw;
      @include media("<=tablet") {
        width: 75vw;
      }
    }
  }
  .contact--formContainer {
    @include m.simpleFlexy();
    @include m.bounceToLeft(6.25rem, -1.25rem);
    flex-direction: column;
    z-index: 950;
    width: 45vw;
    .centered--contact-sectionTitle {
      @include m.simpleFlexy();
      gap: 0.55rem;
      .contact--messageIcon {
        @include m.sectionTitle();
        margin: 0 !important;
        filter: drop-shadow(0.1rem 0.07rem black );
      }
    }
    .contact--form {
      padding: 0 calc(2rem * var(--text-multiplier1));
      width: 100%;
      .contact--formFieldContainer {
        display: flex;
        margin: calc(1rem * var(--text-multiplier3)) 0;
        gap: 0.5rem;
        .contact--formField {
          width: 100%;
          background-color: var(--color-pp);
          color: var(--color-bg);
          box-shadow: 0 0.1875rem 0.1875rem 0 var(--blob-color);
          font-size: calc(1.1rem * var(--text-multiplier3));
          padding: calc(0.7rem * var(--text-multiplier3));
          border-radius: 0.5rem;
          outline: none;
          border: none;
          &::placeholder {
            color: var(--color-bg);
            opacity: 0.75;
          }
          &:focus {
            box-shadow: 0 0.1875rem 0.1875rem 0 black;
            border: none;
          }
          &:focus::placeholder {
            opacity: 0;
          }
        }
        textarea {
          resize: none;
          height: auto;
          cursor: auto;
        }
      }
      .contact--submitBtn {
        .send--iconContainer {
          @include m.simpleFlexy();
          gap: 0.7rem;
        }
      }
    }
    @include media("<=tablet") {
      width: 92vw;
    }
  }
  @include media("<=tablet") {
    flex-direction: column-reverse;
    padding: 2rem 0 1rem;
  }
  .redundantSVG2 {
    top: 9.5rem;
    width: calc(27rem * var(--text-multiplier1)) !important;
    @include m.opacityAndBlur();
    transform: scaleX(-1);
    @include media (">1440px") {
      top: 18rem;
    }
  }
}

.contact--sendSuccessPopUpDiv {
  @include m.simpleOpacityAnimation();
  @include m.simpleFlexy();
  @include m.forPopUp();
  flex-direction: column;
  gap: 3rem;
  padding: calc(2.7rem * var(--text-multiplier2)) calc(2rem * var(--text-multiplier2));
  color: var(--color-pp);
  width: calc(55vw * var(--widthGrower));
  max-height: 30rem;
  text-align: center;
  border-radius: 1rem; 
  transition: all 0.5s ease-out, transform 0s none;
  .contact--iconOK {
    font-size: calc(7rem * var(--text-multiplier2));
  }
  .sendSuccessBtn {
    font-size: calc(1.35rem * var(--text-multiplier3));
  }
}

.contact--submitBtn, .sendSuccessBtn {
  @include m.mainButton();
  margin-top: calc(0.5rem * var(--text-multiplier3));
  border-radius: 0.5rem;
  border: none;
  box-shadow: 0 0.1875rem 0.1875rem 0 var(--blob-color);
  transition: all 0.3s ease-in-out;
  &:hover {
      box-shadow: 0 0.1875rem 0.1875rem 0 black;
      background-color: var(--blob-color);
  }
  &:active {
    @include m.onButtonActive(0.4rem, var(--color-bg));
  }
}