:root {
  --color-bg: #f6f8ff;
  --color-pp: #272d2d;
  --main-whitey: #f6f8ff;
  --main-blackey: #272d2d;
  --color-forRainbow1: #e994a5;
  --color-forRainbow2: #ffdb98;
  --color-forRainbow3: #aaf7ff;
  --color-forRainbow4: #b4ffd6;
  --color-forRainbow5: #edffbe;
  --gradient: linear-gradient(
    125deg,
    var(--color-forRainbow1),
    var(--color-forRainbow2),
    var(--color-forRainbow3),
    var(--color-forRainbow4),
    var(--color-forRainbow5)
  );
  --blob-color: #c41437;
  --white: #fff;
  --black: #000;
  --min-height: calc(100vh - 4.125rem - 6.510625rem); /* calc(100vh - 3.875rem - 6.510625rem); */
  --text-multiplier: 1;
  --text-multiplier1: 1;
  --text-multiplier2: 1;
  --text-multiplier3: 1;
  --widthGrower: 1;
}
@media (min-width: 951px) and (max-width: 1023px) {
  :root {
    --text-multiplier: 0.9;
    --text-multiplier1: 0.75;
    --text-multiplier2: 0.75;
    --text-multiplier3: 0.9;
    --widthGrower: 1.33333333333;
  }
}
@media (min-width: 769px) and (max-width: 950px) {
  :root {
    --text-multiplier: 0.85;
    --text-multiplier1: 0.65;
    --text-multiplier2: 0.75;
    --text-multiplier3: 0.85;
    --widthGrower: 1.33333333333;
  }
}
@media (max-width: 768px) {
  :root {
    --text-multiplier1: 0.55;
    --text-multiplier2: 0.75;
    --text-multiplier3: 0.85;
    --widthGrower: 1.5;
  }
}
@media (min-width: 1441px) and (max-width: 1679px) {
  :root {
    --text-multiplier: 1.2;
    --text-multiplier1: 1.1;
    --text-multiplier2: 1.1;
    --text-multiplier3: 1.15;
  }
}
@media (min-width: 1680px) {
  :root {
    --text-multiplier: 1.4;
    --text-multiplier1: 1.25;
    --text-multiplier2: 1.25;
    --text-multiplier3: 1.3;
  }
}

.darkMode {
  --color-bg: #272d2d;
  --color-pp: #f6f8ff;
  --color-forRainbow1: #c41437;
  --color-forRainbow2: #725e88;
  --color-forRainbow3: #9e560e;
  --color-forRainbow4: #c53c75;
  --color-forRainbow5: #a4b183;
  --gradient: linear-gradient(
    125deg,
    var(--color-forRainbow1),
    var(--color-forRainbow2),
    var(--color-forRainbow3),
    var(--color-forRainbow4),
    var(--color-forRainbow5)
  );
  --blob-color: #FCE100;
  --white: #000;
  --black: #fff;
}

.nav--container {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 997;
  animation: 0.5s ease-out 0s 1 simpleSlideUpDown;
  -webkit-animation: 0.5s ease-out 0s 1 simpleSlideUpDown;
  -moz-animation: 0.5s ease-out 0s 1 simpleSlideUpDown;
  -o-animation: 0.5s ease-out 0s 1 simpleSlideUpDown;
}
@keyframes simpleSlideUpDown {
  0% {
    transform: translateY(-100%);
    opacity: 0.2;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes simpleSlideUpDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.nav--container .nav--logoContainer {
  padding-top: 0.25rem;
}
.nav--container .img--logo {
  width: calc(3.5rem * var(--text-multiplier));
  border-bottom: 0.125rem solid var(--color-pp);
  border-radius: 50%;
  background-color: var(--blob-color);
  position: relative;
  z-index: 999;
  transition: all 0.3s ease-out;
}
.nav--container .img--logo:hover {
  filter: brightness(85%);
}
.nav--container .nav--links {
  display: flex;
  justify-content: space-between;
  transition: all 0.5s ease-out;
}
@media (max-width: 768px) {
  .nav--container .nav--links {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -50rem;
    z-index: -1;
    opacity: 0;
    width: 100%;
    background-color: var(--color-bg);
  }
}
.nav--container .nav--links#menuOpen {
  position: absolute;
  top: 4.15rem;
  z-index: 998;
  opacity: 1;
  border: solid 0.6rem var(--color-pp);
}
.nav--container .nav--links .nav--link {
  font-size: calc(1.7rem * var(--text-multiplier));
  letter-spacing: -2px;
  text-decoration: none;
  color: var(--black);
  margin: 0 0.8rem;
  transition: all 0.3s ease;
  border: none;
  position: relative;
}
.nav--container .nav--links .nav--link:hover {
  color: var(--white);
}
.nav--container .nav--links .nav--link:hover::after {
  width: 100%;
}
.nav--container .nav--links .nav--link::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  display: block;
  background: var(--color-pp);
  transition: all 0.3s ease-in-out;
  z-index: -10;
}
@media (max-width: 768px) {
  .nav--container .nav--links .nav--link {
    margin: 1.2rem 0;
  }
}
.nav--container .nav--links .nav--link-active {
  background: var(--color-bg);
}
.nav--container .nav--links .nav--link-active:hover {
  color: var(--black);
  cursor: default;
}
.nav--container .nav--links .nav--link-active::after {
  content: "";
  border-bottom: solid 0.4rem var(--blob-color);
  width: 100%;
  display: block;
}
.nav--container .nav--links .projects--button {
  background: var(--gradient);
  background-size: 1800% 1800%;
  animation: rainbow 4s ease infinite;
  -webkit-animation: rainbow 4s ease infinite;
  -moz-animation: rainbow 4s ease infinite;
  -o-animation: rainbow 4s ease infinite;
}
@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
.nav--container .nav--links .projects--button:hover {
  background: var(--color-pp);
  color: var(--white);
}
.nav--container #themeBtnContainer, .nav--container #mobile--menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav--container #themeBtnContainer .theme--toggleIcon, .nav--container #themeBtnContainer .menu--toggleIcon, .nav--container #mobile--menu .theme--toggleIcon, .nav--container #mobile--menu .menu--toggleIcon {
  cursor: pointer;
  transition: all 0.3s ease;
}
.nav--container #themeBtnContainer .theme--toggleIcon:hover, .nav--container #themeBtnContainer .menu--toggleIcon:hover, .nav--container #mobile--menu .theme--toggleIcon:hover, .nav--container #mobile--menu .menu--toggleIcon:hover {
  color: var(--blob-color);
}
.nav--container #themeBtnContainer .theme--toggleIcon, .nav--container #mobile--menu .theme--toggleIcon {
  font-size: calc(2.2rem * var(--text-multiplier));
}
.nav--container #themeBtnContainer .menu--toggleIcon, .nav--container #mobile--menu .menu--toggleIcon {
  display: none;
  font-size: calc(2.6rem * var(--text-multiplier));
}
@media (max-width: 768px) {
  .nav--container #themeBtnContainer .menu--toggleIcon, .nav--container #mobile--menu .menu--toggleIcon {
    display: block;
  }
}

.nav--container-active {
  box-shadow: 0px 4.5px 8.5px -8px var(--color-pp);
  -webkit-box-shadow: 0px 4.5px 8.5px -8px var(--color-pp);
  -moz-box-shadow: 0px 4.5px 8.5px -8px var(--color-pp);
  transition: box-shadow 0.15s ease-in;
  background-color: var(--color-bg);
}

.footer--container {
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  position: relative;
  text-align: center;
  padding: 1.2rem 1rem 0.3rem;
  z-index: 997;
}
@-webkit-keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.footer--container .footer--iconsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
  margin-bottom: 0.3rem;
  transition: all 0.5s ease-in-out;
}
.footer--container .footer--iconsContainer a {
  color: var(--color-pp);
}
.footer--container .footer--iconsContainer a:hover {
  color: var(--blob-color);
  transform: translateY(-10%) scale(1.1);
  transition: transform 0.1s ease-out;
}
.footer--container .footer--spanTextContainer {
  margin: 0.6rem 0 0.5rem;
}
.footer--container .footer--spanTextContainer span {
  opacity: 0.85;
  color: var(--color-pp);
  font-size: calc(0.8rem * var(--text-multiplier));
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.footer--container .footer--spanTextContainer span p {
  font-size: calc(0.75rem * var(--text-multiplier));
  margin-top: 0.1rem;
}

.error--container {
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  flex-direction: column;
  min-height: var(--min-height);
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.error--container h1 {
  font-size: calc(4.5rem * var(--text-multiplier2));
}
.error--container p {
  font-size: calc(1.5rem * var(--text-multiplier2));
}
.error--container .error--reactIcon {
  font-size: calc(14rem * var(--text-multiplier2));
  color: var(--color-pp);
  opacity: 0.6;
}
.error--container .error--backLink {
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  color: var(--color-pp);
  font-size: calc(1.8rem * var(--text-multiplier2));
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.error--container .error--backLink:hover {
  color: var(--blob-color);
}

.home--container {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  align-items: center;
  justify-items: center;
  padding: 1.5rem 13.5vw 0;
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  min-height: var(--min-height);
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.home--container section {
  z-index: 888;
}
.home--container .home--heroText {
  max-width: 37vw; /* 26rem */
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.home--container .home--heroText::before {
  content: "Hi!";
  position: absolute;
  background: var(--gradient), 80%;
  color: var(--blob-color);
  animation: rainbow 4s ease infinite;
  -webkit-animation: rainbow 4s ease infinite;
  -moz-animation: rainbow 4s ease infinite;
  -o-animation: rainbow 4s ease infinite;
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Seaweed Script", cursive;
  font-size: calc(3.8rem * var(--text-multiplier2));
  transform: rotate(-36deg) translate(0%, -80%);
}
@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@media (max-width: 768px) {
  .home--container .home--heroText::before {
    transform: rotate(-36deg) translate(30%, -75%);
  }
}
.home--container .home--heroText h1 {
  font-weight: 700;
  font-size: calc(1.8rem * var(--text-multiplier));
}
.home--container .home--heroText h1 text {
  font-size: calc(1.3rem * var(--text-multiplier));
  font-weight: 500;
  opacity: 0.75;
  font-family: "Courier Prime", monospace;
}
@media (max-width: 768px) {
  .home--container .home--heroText h1 text {
    font-size: calc(1rem * var(--text-multiplier));
  }
}
.home--container .home--heroText p {
  font-size: calc(1.2rem * var(--text-multiplier3));
  font-family: "Courier Prime", monospace;
  margin: calc(1.8rem * var(--text-multiplier1)) 0 calc(1.5rem * var(--text-multiplier2));
}
.home--container .home--heroText p .hero--spanCompEngg {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  text-underline-position: under;
  -webkit-text-decoration-color: var(--blob-color);
          text-decoration-color: var(--blob-color);
}
.home--container .home--heroText p .hero--spanCompEngg:hover {
  color: var(--color-bg);
  background-color: var(--color-pp);
  text-decoration: none;
}
.home--container .home--heroText p .dynamic--words {
  color: var(--blob-color);
  font-size: calc(1.2rem * var(--text-multiplier3));
  font-weight: 600;
  font-family: "Courier Prime", monospace;
  display: none;
}
.home--container .home--heroText p .dynamic--words.text-in {
  display: inline-block;
  -webkit-animation: textIn 0.5s ease;
          animation: textIn 0.5s ease;
}
@-webkit-keyframes textIn {
  0% {
    transform: translateY(125%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes textIn {
  0% {
    transform: translateY(125%);
  }
  100% {
    transform: translateY(0%);
  }
}
.home--container .home--heroText p .dynamic--words.text-out {
  -webkit-animation: textOut 0.5s ease;
          animation: textOut 0.5s ease;
}
@-webkit-keyframes textOut {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-125%);
  }
}
@keyframes textOut {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-125%);
  }
}
.home--container .home--heroText a {
  text-align: center;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.home--container .home--heroText a .home--heroButton {
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
  -webkit-animation: 0.5s ease-out 0s 1 slideInFromLeft;
  -moz-animation: 0.5s ease-out 0s 1 slideInFromLeft;
  -o-animation: 0.5s ease-out 0s 1 slideInFromLeft;
  background-color: var(--color-pp);
  padding: calc(0.7rem * var(--text-multiplier)) calc(1.2rem * var(--text-multiplier));
  color: var(--white);
  font-size: calc(1.1rem * var(--text-multiplier));
  font-weight: 600;
  cursor: pointer;
  border-radius: 0.35rem;
  border: none;
  transition: all 0.3s ease-in-out;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-9%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slideInFromLeft {
  0% {
    transform: translateX(-9%);
  }
  100% {
    transform: translateX(0);
  }
}
.home--container .home--heroText a .home--heroButton .center--heroLinkedin {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
}
.home--container .home--heroText a .home--heroButton .center--heroLinkedin .linkedin--icon {
  font-size: calc(1.8rem * var(--text-multiplier3));
}
.home--container .home--heroText a .home--heroButton:active {
  box-shadow: inset 0rem 0rem 0.7rem var(--color-bg);
  outline: none;
  transition: none;
}
.home--container .home--heroText a .home--heroButton:hover {
  background-color: var(--blob-color);
  color: var(--color-bg);
}
@media (max-width: 768px) {
  .home--container .home--heroText a .home--heroButton {
    font-size: calc(1rem * var(--text-multiplier));
    padding: 0.4rem 0.8rem;
  }
}
@media (max-width: 768px) {
  .home--container .home--heroText {
    max-width: 82vw;
  }
}
.home--container .home--heroImage .hero--img {
  animation: 0.5s ease-out 0s 1 slideInFrom;
  -webkit-animation: 0.5s ease-out 0s 1 slideInFrom;
  -moz-animation: 0.5s ease-out 0s 1 slideInFrom;
  -o-animation: 0.5s ease-out 0s 1 slideInFrom;
  width: 24vw;
}
@keyframes slideInFrom {
  0% {
    transform: translate(9%, 0%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@-webkit-keyframes slideInFrom {
  0% {
    transform: translate(9%, 0%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@media (max-width: 768px) {
  .home--container .home--heroImage .hero--img {
    width: 45vw;
  }
}
.home--container .blob-motion {
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  position: absolute;
  transform: translate(0%, 0%);
  width: 75%;
  z-index: 0;
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .home--container .blob-motion {
    width: 100%;
    transform: translate(-25%, 23%);
  }
}
@media (max-width: 375px) {
  .home--container .blob-motion {
    transform: translate(-25%, 15%);
  }
}
@media (min-width: 1600px) {
  .home--container .blob-motion {
    transform: translate(5%, 0%);
  }
}
@media (max-width: 768px) {
  .home--container {
    display: grid;
    grid-template: auto auto/82vw;
    justify-content: center;
    row-gap: 1.5rem;
    padding: 4rem 9vw 0; /* 3.5rem */
  }
}

.about--container {
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 calc(10vw * var(--text-multiplier2)) 1.4rem;
  margin-bottom: calc(2rem * var(--text-multiplier1));
  min-height: var(--min-height);
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.about--container .centered--about-sectionTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.about--container .centered--about-sectionTitle .about--sectionTitle, .about--container .centered--about-sectionTitle .about--sectionIcon {
  font-size: calc(2.4rem * var(--text-multiplier2));
  text-align: center;
  color: var(--blob-color);
  margin: calc(2rem * var(--text-multiplier2)) calc(1rem * var(--text-multiplier2));
  text-shadow: 0.08rem 0.05rem black;
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.about--container .centered--about-sectionTitle .about--sectionIcon {
  margin: 0 !important;
  filter: drop-shadow(0.08rem 0.05rem black);
}
.about--container .verticalTimelineClass {
  z-index: 950;
  margin-bottom: calc(0.8rem * var(--text-multiplier1));
}
.about--container .redundantSVG1, .about--container .redundantSVG2, .about--container .redundantSVG3 {
  width: calc(14rem * var(--text-multiplier2));
}
.about--container .redundantSVG1 {
  top: calc(90.5rem * var(--text-multiplier3));
  animation: bounceToRight 0.6s;
  -webkit-animation: bounceToRight 0.6s;
  -moz-animation: bounceToRight 0.6s;
  -o-animation: bounceToRight 0.6s;
}
@keyframes bounceToRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-webkit-keyframes bounceToRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@media (min-width: 1441px) {
  .about--container .redundantSVG1 {
    top: 82rem;
  }
}
.about--container .redundantSVG2, .about--container .redundantSVG3 {
  animation: bounceToLeft 0.6s;
  -webkit-animation: bounceToLeft 0.6s;
  -moz-animation: bounceToLeft 0.6s;
  -o-animation: bounceToLeft 0.6s;
}
@keyframes bounceToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-webkit-keyframes bounceToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
.about--container .redundantSVG3 {
  top: 64rem;
  right: 10rem;
  transform: scaleX(-1);
}

.skills--container {
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: var(--min-height);
  gap: calc(3.8rem * var(--text-multiplier3));
  padding: 2.5rem 0 1.5rem;
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .skills--container {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
    row-gap: 3.3rem;
    padding: 2.5rem 2vw 1rem;
  }
}
.skills--container .skills--technicalClass,
.skills--container .skills--creativeClass {
  z-index: 950;
  background-color: var(--color-bg);
  border: solid calc(0.3rem * var(--text-multiplier2)) var(--color-pp);
  max-width: calc(60vw * var(--widthGrower));
  padding: 0 calc(3rem * var(--text-multiplier1)) calc(2rem * var(--text-multiplier1));
}
.skills--container .skills--technicalClass .centered--skills-sectionTitle,
.skills--container .skills--creativeClass .centered--skills-sectionTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.skills--container .skills--technicalClass .centered--skills-sectionTitle .skills--sectionTitle, .skills--container .skills--technicalClass .centered--skills-sectionTitle .skills--sectionIcon,
.skills--container .skills--creativeClass .centered--skills-sectionTitle .skills--sectionTitle,
.skills--container .skills--creativeClass .centered--skills-sectionTitle .skills--sectionIcon {
  font-size: calc(2.4rem * var(--text-multiplier2));
  text-align: center;
  color: var(--blob-color);
  margin: calc(2rem * var(--text-multiplier2)) calc(1rem * var(--text-multiplier2));
  text-shadow: 0.08rem 0.05rem black;
}
.skills--container .skills--technicalClass .centered--skills-sectionTitle .skills--sectionIcon,
.skills--container .skills--creativeClass .centered--skills-sectionTitle .skills--sectionIcon {
  margin: 0 !important;
  filter: drop-shadow(0.08rem 0.05rem black);
}
.skills--container .skills--technicalClass p,
.skills--container .skills--creativeClass p {
  margin-bottom: calc(1.5rem * var(--text-multiplier2));
  line-height: 1.6rem;
  font-size: 1rem;
  text-align: left;
}
@media (min-width: 1441px) {
  .skills--container .skills--technicalClass p,
.skills--container .skills--creativeClass p {
    font-size: calc(1rem * var(--text-multiplier3));
  }
}
.skills--container .skills--technicalClass p span,
.skills--container .skills--creativeClass p span {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  text-underline-position: under;
  -webkit-text-decoration-color: var(--blob-color);
          text-decoration-color: var(--blob-color);
}
.skills--container .skills--technicalClass p span:hover,
.skills--container .skills--creativeClass p span:hover {
  color: var(--color-bg);
  background-color: var(--color-pp);
  text-decoration: none;
}
.skills--container .skills--technicalClass .skill--reactIcon,
.skills--container .skills--creativeClass .skill--reactIcon {
  color: var(--color-pp);
  font-size: calc(5rem * var(--text-multiplier1));
  margin: 1rem;
}
.skills--container .skills--technicalClass .skill--reactIcon:hover,
.skills--container .skills--creativeClass .skill--reactIcon:hover {
  color: var(--blob-color);
  transform: translateY(-10%) scale(1.1);
  transition: transform 0.1s ease-out;
}
@media (max-width: 768px) {
  .skills--container .skills--technicalClass,
.skills--container .skills--creativeClass {
    padding: 0 1.3rem 1.1rem;
  }
}
.skills--container .skills--technicalClass {
  box-shadow: 0.6rem 0.6rem 0 0 var(--blob-color);
  animation: bounceToRight 0.6s;
  -webkit-animation: bounceToRight 0.6s;
  -moz-animation: bounceToRight 0.6s;
  -o-animation: bounceToRight 0.6s;
}
@keyframes bounceToRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-webkit-keyframes bounceToRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
.skills--container .skills--creativeClass {
  box-shadow: -0.6rem 0.6rem 0 0 var(--blob-color);
  animation: bounceToLeft 0.6s;
  -webkit-animation: bounceToLeft 0.6s;
  -moz-animation: bounceToLeft 0.6s;
  -o-animation: bounceToLeft 0.6s;
}
@keyframes bounceToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-webkit-keyframes bounceToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
.skills--container #skills--pdfContainer {
  max-width: calc(60vw * var(--widthGrower));
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.skills--container #skills--pdfContainer .skills--licensesPdfBtn {
  background-color: var(--color-pp);
  padding: calc(0.7rem * var(--text-multiplier)) calc(1.2rem * var(--text-multiplier));
  color: var(--white);
  font-size: calc(1.1rem * var(--text-multiplier));
  font-weight: 600;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease-in-out;
  margin: calc(0.3rem * var(--text-multiplier2)) 0 calc(1.4rem * var(--text-multiplier1));
  font-size: calc(1rem * var(--text-multiplier));
}
.skills--container #skills--pdfContainer .skills--licensesPdfBtn div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
}
.skills--container #skills--pdfContainer .skills--licensesPdfBtn:hover {
  background-color: var(--blob-color);
}
.skills--container #skills--pdfContainer .skills--licensesPdfBtn:active {
  box-shadow: inset 0rem 0rem 0.4rem var(--color-bg);
  outline: none;
  transition: none;
}
.skills--container #skills--pdfContainer #pdfIframe {
  animation: bounceToRight 0.6s;
  -webkit-animation: bounceToRight 0.6s;
  -moz-animation: bounceToRight 0.6s;
  -o-animation: bounceToRight 0.6s;
  margin-bottom: calc(1.8rem * var(--text-multiplier1));
}
@keyframes bounceToRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-webkit-keyframes bounceToRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
.skills--container .redundantSVG3 {
  animation: bounceToLeft 0.6s;
  -webkit-animation: bounceToLeft 0.6s;
  -moz-animation: bounceToLeft 0.6s;
  -o-animation: bounceToLeft 0.6s;
}
@keyframes bounceToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-webkit-keyframes bounceToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@media (min-width: 1680px) {
  .skills--container .redundantSVG3 {
    right: 12rem;
  }
}
.skills--container .redundantSVG4 {
  animation: bounceToRight 0.6s;
  -webkit-animation: bounceToRight 0.6s;
  -moz-animation: bounceToRight 0.6s;
  -o-animation: bounceToRight 0.6s;
}
@keyframes bounceToRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-webkit-keyframes bounceToRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@media (min-width: 1680px) {
  .skills--container .redundantSVG4 {
    left: 12rem;
  }
}

.projects--container {
  text-align: center;
  padding: 1.7rem 1rem 1.4rem;
  min-height: var(--min-height);
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.projects--container .projects--mainButton {
  background-color: var(--color-pp);
  padding: calc(0.7rem * var(--text-multiplier)) calc(1.2rem * var(--text-multiplier));
  color: var(--white);
  font-size: calc(1.1rem * var(--text-multiplier));
  font-weight: 600;
  cursor: pointer;
  border: none;
}
.projects--container .projects--mainButton:hover {
  opacity: 0.9;
}
@media (max-width: 768px) {
  .projects--container .projects--mainButton {
    margin-top: 0.8rem;
  }
}
.projects--container .projects--mainButton-active {
  box-shadow: inset 0rem 0rem 0.3rem var(--main-blackey);
  outline: none;
  transition: none;
  cursor: default;
  background-color: var(--blob-color);
  color: var(--white);
  transform: scale(0.85);
  transition: transform 0.3s ease;
}
.projects--container .projects--mainButton-active:hover {
  opacity: 1;
}

.projects--technicalContainer,
.projects--creativeContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  gap: calc(5rem * var(--text-multiplier1));
  margin: calc(3.5rem * var(--text-multiplier)) 0 calc(1rem * var(--text-multiplier));
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.projects--technicalContainer .singleProject--technicalContainer,
.projects--technicalContainer .singleProject--creativeContainer,
.projects--creativeContainer .singleProject--technicalContainer,
.projects--creativeContainer .singleProject--creativeContainer {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-content: center;
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  z-index: 950;
  text-align: center;
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.projects--technicalContainer .singleProject--technicalContainer .projectImage,
.projects--technicalContainer .singleProject--creativeContainer .projectImage,
.projects--creativeContainer .singleProject--technicalContainer .projectImage,
.projects--creativeContainer .singleProject--creativeContainer .projectImage {
  width: calc(25rem * var(--text-multiplier3));
  transition: all 0.7s ease;
  transition: transform 0.3s ease;
}
.projects--technicalContainer .singleProject--technicalContainer .projectImage:hover,
.projects--technicalContainer .singleProject--creativeContainer .projectImage:hover,
.projects--creativeContainer .singleProject--technicalContainer .projectImage:hover,
.projects--creativeContainer .singleProject--creativeContainer .projectImage:hover {
  filter: brightness(90%);
  transform: scale(1.03);
}
.projects--technicalContainer .singleProject--technicalContainer .projectVideo,
.projects--technicalContainer .singleProject--creativeContainer .projectVideo,
.projects--creativeContainer .singleProject--technicalContainer .projectVideo,
.projects--creativeContainer .singleProject--creativeContainer .projectVideo {
  width: 21rem;
  margin-bottom: calc(0.65rem * var(--text-multiplier));
}
.projects--technicalContainer .singleProject--technicalContainer h2,
.projects--technicalContainer .singleProject--creativeContainer h2,
.projects--creativeContainer .singleProject--technicalContainer h2,
.projects--creativeContainer .singleProject--creativeContainer h2 {
  font-size: calc(1.5rem * var(--text-multiplier3));
  margin-bottom: calc(0.25rem * var(--text-multiplier));
}
.projects--technicalContainer .singleProject--technicalContainer p,
.projects--technicalContainer .singleProject--creativeContainer p,
.projects--creativeContainer .singleProject--technicalContainer p,
.projects--creativeContainer .singleProject--creativeContainer p {
  font-weight: 500;
  margin-bottom: calc(0.25rem * var(--text-multiplier));
}
.projects--technicalContainer .singleProject--technicalContainer a,
.projects--technicalContainer .singleProject--creativeContainer a,
.projects--creativeContainer .singleProject--technicalContainer a,
.projects--creativeContainer .singleProject--creativeContainer a {
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-self: center;
}
.projects--technicalContainer .singleProject--technicalContainer a .singleProject--btn,
.projects--technicalContainer .singleProject--creativeContainer a .singleProject--btn,
.projects--creativeContainer .singleProject--technicalContainer a .singleProject--btn,
.projects--creativeContainer .singleProject--creativeContainer a .singleProject--btn {
  background-color: var(--color-bg);
  padding: calc(0.4rem * var(--text-multiplier2)) calc(0.8rem * var(--text-multiplier2));
  margin: calc(0.25rem * var(--text-multiplier2)) 0;
  color: var(--color-pp);
  font-size: calc(1.1rem * var(--text-multiplier));
  font-weight: 600;
  cursor: pointer;
  border-radius: 1rem;
  border: solid 0.125rem var(--color-pp);
  transition: all 0.3s ease-in-out;
}
.projects--technicalContainer .singleProject--technicalContainer a .singleProject--btn .centerDiv--singleProjectIcon,
.projects--technicalContainer .singleProject--creativeContainer a .singleProject--btn .centerDiv--singleProjectIcon,
.projects--creativeContainer .singleProject--technicalContainer a .singleProject--btn .centerDiv--singleProjectIcon,
.projects--creativeContainer .singleProject--creativeContainer a .singleProject--btn .centerDiv--singleProjectIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.projects--technicalContainer .singleProject--technicalContainer a .singleProject--btn .centerDiv--singleProjectIcon .singleProject--icon,
.projects--technicalContainer .singleProject--creativeContainer a .singleProject--btn .centerDiv--singleProjectIcon .singleProject--icon,
.projects--creativeContainer .singleProject--technicalContainer a .singleProject--btn .centerDiv--singleProjectIcon .singleProject--icon,
.projects--creativeContainer .singleProject--creativeContainer a .singleProject--btn .centerDiv--singleProjectIcon .singleProject--icon {
  font-size: calc(1.4rem * var(--text-multiplier2));
}
.projects--technicalContainer .singleProject--technicalContainer a .singleProject--btn:hover,
.projects--technicalContainer .singleProject--creativeContainer a .singleProject--btn:hover,
.projects--creativeContainer .singleProject--technicalContainer a .singleProject--btn:hover,
.projects--creativeContainer .singleProject--creativeContainer a .singleProject--btn:hover {
  color: var(--blob-color);
  border-color: var(--blob-color);
}
.projects--technicalContainer .singleProject--technicalContainer a .singleProject--btn:active,
.projects--technicalContainer .singleProject--creativeContainer a .singleProject--btn:active,
.projects--creativeContainer .singleProject--technicalContainer a .singleProject--btn:active,
.projects--creativeContainer .singleProject--creativeContainer a .singleProject--btn:active {
  box-shadow: inset 0rem 0rem 0.2rem var(--blob-color);
  outline: none;
  transition: none;
  color: var(--color-bg);
  background-color: var(--blob-color);
}
.projects--technicalContainer .singleProject--creativeContainer,
.projects--creativeContainer .singleProject--creativeContainer {
  box-shadow: 0 0 calc(1rem * var(--text-multiplier2)) calc(-0.4rem * var(--text-multiplier2)) var(--color-pp);
  -webkit-box-shadow: 0 0 calc(1rem * var(--text-multiplier2)) calc(-0.4rem * var(--text-multiplier2)) var(--color-pp);
  -moz-box-shadow: 0 0 calc(1rem * var(--text-multiplier2)) calc(-0.4rem * var(--text-multiplier2)) var(--color-pp);
  background-color: var(--color-bg);
  border-radius: 0 0 0.5rem 0.5rem;
  transition: all 0.3s ease;
}
.projects--technicalContainer .singleProject--creativeContainer:hover,
.projects--creativeContainer .singleProject--creativeContainer:hover {
  box-shadow: 0 0 calc(1rem * var(--text-multiplier2)) calc(-0.4rem * var(--text-multiplier2)) var(--blob-color);
  -webkit-box-shadow: 0 0 calc(1rem * var(--text-multiplier2)) calc(-0.4rem * var(--text-multiplier2)) var(--blob-color);
  -moz-box-shadow: 0 0 calc(1rem * var(--text-multiplier2)) calc(-0.4rem * var(--text-multiplier2)) var(--blob-color);
  transform: scale(1.03);
}
.projects--technicalContainer .singleProject--creativeContainer h2, .projects--technicalContainer .singleProject--creativeContainer p,
.projects--creativeContainer .singleProject--creativeContainer h2,
.projects--creativeContainer .singleProject--creativeContainer p {
  cursor: default;
}
.projects--technicalContainer .singleProject--creativeContainer:hover h2,
.projects--creativeContainer .singleProject--creativeContainer:hover h2 {
  color: var(--blob-color);
}
.projects--technicalContainer .redundantSVG1, .projects--technicalContainer .redundantSVG2, .projects--technicalContainer .redundantSVG3, .projects--technicalContainer .redundantSVG4,
.projects--creativeContainer .redundantSVG1,
.projects--creativeContainer .redundantSVG2,
.projects--creativeContainer .redundantSVG3,
.projects--creativeContainer .redundantSVG4 {
  opacity: 0.3;
  filter: blur(0.6rem);
  -webkit-filter: blur(0.6rem);
  transform: translate3d(0, 0, 0);
  width: calc(19rem * var(--text-multiplier1));
}

.projects--technicalContainer .redundantSVG2 {
  top: 30rem;
}
@media (min-width: 1441px) {
  .projects--technicalContainer .redundantSVG2 {
    top: 25rem;
  }
}

.projects--creativeContainer .redundantSVG1, .projects--creativeContainer .redundantSVG2, .projects--creativeContainer .redundantSVG3, .projects--creativeContainer .redundantSVG4 {
  width: calc(33rem * var(--text-multiplier1));
  opacity: 0.2;
  transform: scaleX(-1);
}
@media (max-width: 768px) {
  .projects--creativeContainer .redundantSVG1, .projects--creativeContainer .redundantSVG2, .projects--creativeContainer .redundantSVG3, .projects--creativeContainer .redundantSVG4 {
    width: 11rem !important;
  }
}
.projects--creativeContainer .redundantSVG1 {
  top: 75rem;
}
@media (min-width: 1441px) {
  .projects--creativeContainer .redundantSVG1 {
    top: 55rem;
  }
}

.contact--container-lowOpacity {
  opacity: 0.3;
  filter: blur(0.6rem);
  -webkit-filter: blur(0.6rem);
  transform: translate3d(0, 0, 0);
}

.contact--container, .contact--container-lowOpacity {
  min-height: var(--min-height);
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: calc(1.5rem * var(--text-multiplier3));
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contact--container .contact--imgContainer, .contact--container-lowOpacity .contact--imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: bounceToRight 0.6s;
  -webkit-animation: bounceToRight 0.6s;
  -moz-animation: bounceToRight 0.6s;
  -o-animation: bounceToRight 0.6s;
  z-index: 950;
}
@keyframes bounceToRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-webkit-keyframes bounceToRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
.contact--container .contact--imgContainer .contact--png, .contact--container-lowOpacity .contact--imgContainer .contact--png {
  width: 30vw;
}
@media (max-width: 768px) {
  .contact--container .contact--imgContainer .contact--png, .contact--container-lowOpacity .contact--imgContainer .contact--png {
    width: 75vw;
  }
}
.contact--container .contact--formContainer, .contact--container-lowOpacity .contact--formContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: bounceToLeft 0.6s;
  -webkit-animation: bounceToLeft 0.6s;
  -moz-animation: bounceToLeft 0.6s;
  -o-animation: bounceToLeft 0.6s;
  flex-direction: column;
  z-index: 950;
  width: 45vw;
}
@keyframes bounceToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-webkit-keyframes bounceToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(6.25rem);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-1.25rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
.contact--container .contact--formContainer .centered--contact-sectionTitle, .contact--container-lowOpacity .contact--formContainer .centered--contact-sectionTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.55rem;
}
.contact--container .contact--formContainer .centered--contact-sectionTitle .contact--messageIcon, .contact--container-lowOpacity .contact--formContainer .centered--contact-sectionTitle .contact--messageIcon {
  font-size: calc(2.4rem * var(--text-multiplier2));
  text-align: center;
  color: var(--blob-color);
  margin: calc(2rem * var(--text-multiplier2)) calc(1rem * var(--text-multiplier2));
  text-shadow: 0.08rem 0.05rem black;
  margin: 0 !important;
  filter: drop-shadow(0.1rem 0.07rem black);
}
.contact--container .contact--formContainer .contact--form, .contact--container-lowOpacity .contact--formContainer .contact--form {
  padding: 0 calc(2rem * var(--text-multiplier1));
  width: 100%;
}
.contact--container .contact--formContainer .contact--form .contact--formFieldContainer, .contact--container-lowOpacity .contact--formContainer .contact--form .contact--formFieldContainer {
  display: flex;
  margin: calc(1rem * var(--text-multiplier3)) 0;
  gap: 0.5rem;
}
.contact--container .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField, .contact--container-lowOpacity .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField {
  width: 100%;
  background-color: var(--color-pp);
  color: var(--color-bg);
  box-shadow: 0 0.1875rem 0.1875rem 0 var(--blob-color);
  font-size: calc(1.1rem * var(--text-multiplier3));
  padding: calc(0.7rem * var(--text-multiplier3));
  border-radius: 0.5rem;
  outline: none;
  border: none;
}
.contact--container .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField::-moz-placeholder, .contact--container-lowOpacity .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField::-moz-placeholder {
  color: var(--color-bg);
  opacity: 0.75;
}
.contact--container .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField:-ms-input-placeholder, .contact--container-lowOpacity .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField:-ms-input-placeholder {
  color: var(--color-bg);
  opacity: 0.75;
}
.contact--container .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField::placeholder, .contact--container-lowOpacity .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField::placeholder {
  color: var(--color-bg);
  opacity: 0.75;
}
.contact--container .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField:focus, .contact--container-lowOpacity .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField:focus {
  box-shadow: 0 0.1875rem 0.1875rem 0 black;
  border: none;
}
.contact--container .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField:focus::-moz-placeholder, .contact--container-lowOpacity .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField:focus::-moz-placeholder {
  opacity: 0;
}
.contact--container .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField:focus:-ms-input-placeholder, .contact--container-lowOpacity .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField:focus:-ms-input-placeholder {
  opacity: 0;
}
.contact--container .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField:focus::placeholder, .contact--container-lowOpacity .contact--formContainer .contact--form .contact--formFieldContainer .contact--formField:focus::placeholder {
  opacity: 0;
}
.contact--container .contact--formContainer .contact--form .contact--formFieldContainer textarea, .contact--container-lowOpacity .contact--formContainer .contact--form .contact--formFieldContainer textarea {
  resize: none;
  height: auto;
  cursor: auto;
}
.contact--container .contact--formContainer .contact--form .contact--submitBtn .send--iconContainer, .contact--container-lowOpacity .contact--formContainer .contact--form .contact--submitBtn .send--iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
}
@media (max-width: 768px) {
  .contact--container .contact--formContainer, .contact--container-lowOpacity .contact--formContainer {
    width: 92vw;
  }
}
@media (max-width: 768px) {
  .contact--container, .contact--container-lowOpacity {
    flex-direction: column-reverse;
    padding: 2rem 0 1rem;
  }
}
.contact--container .redundantSVG2, .contact--container-lowOpacity .redundantSVG2 {
  top: 9.5rem;
  width: calc(27rem * var(--text-multiplier1)) !important;
  opacity: 0.3;
  filter: blur(0.6rem);
  -webkit-filter: blur(0.6rem);
  transform: translate3d(0, 0, 0);
  transform: scaleX(-1);
}
@media (min-width: 1441px) {
  .contact--container .redundantSVG2, .contact--container-lowOpacity .redundantSVG2 {
    top: 18rem;
  }
}

.contact--sendSuccessPopUpDiv {
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: solid calc(0.35rem * var(--text-multiplier2)) var(--color-pp);
  box-shadow: 0.7rem 0.7rem 0 0 var(--blob-color);
  background-color: var(--color-bg);
  flex-direction: column;
  gap: 3rem;
  padding: calc(2.7rem * var(--text-multiplier2)) calc(2rem * var(--text-multiplier2));
  color: var(--color-pp);
  width: calc(55vw * var(--widthGrower));
  max-height: 30rem;
  text-align: center;
  border-radius: 1rem;
  transition: all 0.5s ease-out, transform 0s none;
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contact--sendSuccessPopUpDiv .contact--iconOK {
  font-size: calc(7rem * var(--text-multiplier2));
}
.contact--sendSuccessPopUpDiv .sendSuccessBtn {
  font-size: calc(1.35rem * var(--text-multiplier3));
}

.contact--submitBtn, .sendSuccessBtn {
  background-color: var(--color-pp);
  padding: calc(0.7rem * var(--text-multiplier)) calc(1.2rem * var(--text-multiplier));
  color: var(--white);
  font-size: calc(1.1rem * var(--text-multiplier));
  font-weight: 600;
  cursor: pointer;
  margin-top: calc(0.5rem * var(--text-multiplier3));
  border-radius: 0.5rem;
  border: none;
  box-shadow: 0 0.1875rem 0.1875rem 0 var(--blob-color);
  transition: all 0.3s ease-in-out;
}
.contact--submitBtn:hover, .sendSuccessBtn:hover {
  box-shadow: 0 0.1875rem 0.1875rem 0 black;
  background-color: var(--blob-color);
}
.contact--submitBtn:active, .sendSuccessBtn:active {
  box-shadow: inset 0rem 0rem 0.4rem var(--color-bg);
  outline: none;
  transition: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

* img {
  -webkit-user-drag: none;
}

main {
  background-color: var(--color-bg);
  color: var(--color-pp);
  transition: all 500ms ease-in-out;
  min-height: 100vh;
}
main .scrollUp--button {
  position: fixed;
  bottom: 10%;
  right: 6%;
  z-index: 9999;
  color: var(--color-bg);
  background-color: var(--blob-color);
  font-size: 3rem;
  cursor: pointer;
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  transition: all 0.3s ease-in-out;
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
main .scrollUp--button:hover {
  color: var(--blob-color);
  transform: translateY(-10%) scale(1.1);
  transition: transform 0.1s ease-out;
  color: var(--blob-color);
  background-color: var(--color-bg);
  border: 0.2rem solid var(--blob-color);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 0.85rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--black);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsl(0deg, 0%, 66%);
  border-radius: 0.35rem;
  /* Handle on hover */
  /* Handle on active */
}
::-webkit-scrollbar-thumb:hover {
  background: hsl(0deg, 0%, 80%);
  box-shadow: inset 0rem 0rem 0.25rem hsl(0deg, 0%, 69%);
  outline: none;
  -webkit-transition: none;
  transition: none;
}
::-webkit-scrollbar-thumb:active {
  background: var(--white);
  box-shadow: inset 0rem 0rem 0.4rem hsl(0deg, 0%, 30%);
  outline: none;
  -webkit-transition: none;
  transition: none;
}

.redundantSVG1, .redundantSVG2, .redundantSVG3, .redundantSVG4 {
  width: 14rem;
  overflow: hidden !important;
  -webkit-animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
          animation: 0.9s ease-out 0s 1 simpleOpacityAnimation;
  transition: all 500ms ease-in-out;
  position: absolute;
}
@keyframes simpleOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 950px) {
  .redundantSVG1, .redundantSVG2, .redundantSVG3, .redundantSVG4 {
    opacity: 0.3;
    filter: blur(0.6rem);
    -webkit-filter: blur(0.6rem);
    transform: translate3d(0, 0, 0);
  }
}

.redundantSVG1 {
  top: 90rem;
  left: 10rem;
}

.redundantSVG2 {
  top: 15rem;
  right: 10rem;
}

.redundantSVG3 {
  top: 56rem;
  right: 7rem;
}

.redundantSVG4 {
  top: 15rem;
  left: 9rem;
}/*# sourceMappingURL=app.css.map */