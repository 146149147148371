@use '../Abstracts/mixins' as m;
@use '../Abstracts/breakpoints' as *;
@use '../Abstracts/vars' as *;


.nav--container {
    @include m.navmaingriddy();
    position: sticky;
    top: 0;
    z-index: 997;
    @include m.simpleSlideUpDown(-100%);
    .nav--logoContainer {
        padding-top: 0.25rem;
    }
    .img--logo {
        width: calc(3.5rem * var(--text-multiplier));
        border-bottom: 0.125rem solid var(--color-pp);
        border-radius: 50%;
        background-color: var(--blob-color);
        position: relative;
        z-index: 999;
        transition: all 0.3s ease-out;
        &:hover {
            filter: brightness(85%);
        }
    }

    .nav--links {
        @include m.flexy();
        transition: all 0.5s ease-out;
        @include media("<=tablet") {
            @include m.griddy();
            position: absolute;
            top: -50rem;
            z-index: -1;
            opacity: 0;
            width: 100%;
            background-color: var(--color-bg);
        }
        &#menuOpen {
            position: absolute;
            top: 4.15rem;
            z-index: 998;
            opacity: 1;
            border: solid 0.6rem var(--color-pp);
        }
        .nav--link {
            font-size: calc(1.7rem * var(--text-multiplier));
            letter-spacing: -2px;
            text-decoration: none;
            color: var(--black);
            margin: 0 0.8rem;
            transition: all 0.3s ease;
            border: none;
            position: relative;
            &:hover {
                color: var(--white);
                &::after {
                    width: 100%;
                }
            }
            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                display: block;
                background: var(--color-pp);
                transition: all 0.3s ease-in-out;
                z-index: -10;
            }
            @include media("<=tablet") {
                margin: 1.2rem 0;
            }
        }
        .nav--link-active {
            background: var(--color-bg);
            &:hover {
                color: var(--black);
                cursor: default;
            }
            &::after {
                content: '';
                border-bottom: solid 0.4rem var(--blob-color);
                width: 100%;
                display: block;  
            }
        }
        .projects--button {
            background: var(--gradient);
            background-size: 1800% 1800%;
            @include m.rainbowAnimation();
            &:hover {
                background: var(--color-pp);
                color: var(--white);
            }
        }
    }

    #themeBtnContainer, #mobile--menu {
        @include m.simpleFlexy();
        .theme--toggleIcon, .menu--toggleIcon {
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
                color: var(--blob-color);
            }
        } 
        .theme--toggleIcon {
            font-size: calc(2.2rem * var(--text-multiplier));
        }
        .menu--toggleIcon {
            display: none;
            font-size: calc(2.6rem * var(--text-multiplier));
            @include media("<=tablet") {
                display: block;
            }
        }
    }
}

.nav--container-active {
    box-shadow: 0px 4.5px 8.5px -8px var(--color-pp);
    -webkit-box-shadow: 0px 4.5px 8.5px -8px var(--color-pp);
    -moz-box-shadow: 0px 4.5px 8.5px -8px var(--color-pp);    
    transition: box-shadow 0.15s ease-in;
    background-color: var(--color-bg);
}







