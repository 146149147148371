@use './breakpoints' as *;

:root {
  // COLORS
  --color-bg: #f6f8ff;
  --color-pp: #272d2d;
  --main-whitey: #f6f8ff;
  --main-blackey: #272d2d;
  --color-forRainbow1: #e994a5;
  --color-forRainbow2: #ffdb98;
  --color-forRainbow3: #aaf7ff;
  --color-forRainbow4: #b4ffd6;
  --color-forRainbow5: #edffbe;
  --gradient: linear-gradient(
    125deg,
    var(--color-forRainbow1),
    var(--color-forRainbow2),
    var(--color-forRainbow3),
    var(--color-forRainbow4),
    var(--color-forRainbow5)
  );
  --blob-color: #c41437;
  --white: #fff;
  --black: #000;

  // MIN HEIGHT 
  --min-height: calc(100vh - 4.125rem - 6.510625rem); /* calc(100vh - 3.875rem - 6.510625rem); */ 

  // MULTIPLIERS & GROWERS 
  --text-multiplier: 1;
  --text-multiplier1: 1;
  --text-multiplier2: 1;
  --text-multiplier3: 1;
  --widthGrower: 1;
  @include media (">950px", "<desktop") {
    --text-multiplier: 0.9;
    --text-multiplier1: 0.75;
    --text-multiplier2: 0.75;
    --text-multiplier3: 0.9;
    --widthGrower: 1.33333333333;
  }
  @include media (">tablet", "<=950px") {
    --text-multiplier: 0.85;
    --text-multiplier1: 0.65;
    --text-multiplier2: 0.75;
    --text-multiplier3: 0.85;
    --widthGrower: 1.33333333333;
  }
  @include media ("<=tablet") {
    --text-multiplier1: 0.55;
    --text-multiplier2: 0.75;
    --text-multiplier3: 0.85;
    --widthGrower: 1.5;
  }
  @include media (">1440px", "<1680px") {
    --text-multiplier: 1.2;
    --text-multiplier1: 1.1;
    --text-multiplier2: 1.1;
    --text-multiplier3: 1.15;
  }
  @include media (">=1680px") {
    --text-multiplier: 1.4;
    --text-multiplier1: 1.25;
    --text-multiplier2: 1.25;
    --text-multiplier3: 1.3;
  }
}

.darkMode {
  --color-bg: #272d2d;
  --color-pp: #f6f8ff;
  --color-forRainbow1: #c41437;
  --color-forRainbow2: #725e88;
  --color-forRainbow3: #9e560e;
  --color-forRainbow4: #c53c75;
  --color-forRainbow5: #a4b183;
  --gradient: linear-gradient(
    125deg,
    var(--color-forRainbow1),
    var(--color-forRainbow2),
    var(--color-forRainbow3),
    var(--color-forRainbow4),
    var(--color-forRainbow5)
  );
  --blob-color: #FCE100;
  --white: #000;
  --black: #fff;
}


